<template>
    <div class="detail">
        <!-- 详情页 -->
        <div class="WebMain">
            <div class="top">
                <p class="title p24">{{ articleInfo.title }}</p>
                <p class="time p17">{{ (articleInfo.created).slice(0, 10) }}</p>
            </div>
            <div class="content" v-html="articleInfo.content">
            </div>
            <div class="bottomBtn">
                <span @click="backList">返回列表</span>
            </div>
        </div>
    </div>
</template>

<script>
import { articleDetailAPI } from '@/api/article/article'
export default {
    data() {
        return {
            articleInfo: {
                created: ''
            }
        }
    },
    methods: {
        // 获取文章详情
        async getDetail() {
            const res = await articleDetailAPI({ id: this.$route.params.id });
            console.log(res);
            if (res.code === 200) {
                this.articleInfo = res.data;
            }
        },
        backList() {
            this.$router.push('/trends')
        }
    },
    created() {
        this.getDetail();
    }
}
</script>

<style lang="scss" scoped>
.detail {

    background: #F5F5F5;

    .WebMain {
        // min-height: 300px;
        min-height: 500px;
        background: #fff;
        padding: 40px 50px;
        padding-bottom: 60px;
        box-sizing: border-box;

        .top {
            padding-bottom: 30px;
            border-bottom: 1px solid #222222;

            .title {
                margin-bottom: 15px;
                color: #222222;
                line-height: 29px;
                font-weight: 600;
            }
        }

        .content {
            padding: 35px 0px;
            border-bottom: 1px solid #222222;
            min-height: 300px;
            ::v-deep p{
               img{
                max-width: 100% !important;
                margin-top: 20px;
                margin-bottom: 20px;
            } 
            }
        }


        .bottomBtn {
            padding-top: 40px;
            width: 100%;
            span {
                display: block;
                width: 143px;
                text-align: center;
                // height: 42px;
                line-height: 42px;
                background: var(--custom-color);
                color: white;
                margin: auto;
                cursor: pointer;
            }
        }
    }
}
</style>